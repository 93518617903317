/**
 * Box Component
 * View component with access to styled system props
 */

import styled from 'styled-components';
import {
  BorderProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TextAlignProps,
  TypographyProps,
  border,
  compose,
  flexbox,
  layout,
  position,
  shadow,
  space,
  textAlign,
  typography,
} from 'styled-system';

type BaseBoxProps = SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  ShadowProps &
  TypographyProps &
  TextAlignProps;

export type BoxProps = Omit<BaseBoxProps, 'color' | 'backgroundColor' | 'borderColor'> & {
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  currentTimeStamp?: string;
};

export const Box = styled.div<BoxProps>`
  ${compose(space, flexbox, border, layout, position, textAlign, shadow, typography)}
`;
