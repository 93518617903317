import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export interface UseWindowSizeConfig {
  includeScrollArea?: boolean;
}

export default function useWindowSize({ includeScrollArea }: UseWindowSizeConfig = {}) {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  const handleResize = () => {
    // Set window width/height to state
    setWindowSize(
      includeScrollArea
        ? {
            width: document.body.scrollWidth,
            height: document.body.scrollHeight,
          }
        : {
            width: window.innerWidth,
            height: window.innerHeight,
          }
    );
  };

  // Handler to call on window resize
  const handleResizeDebounce = useDebouncedCallback(handleResize, 300, { trailing: true });

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', handleResizeDebounce);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResizeDebounce]);

  return { windowSize };
}
