import { useEffect, useMemo, useState } from 'react';

import { breakpointsRaw } from '../theme/tokens/breakpoints';
import useWindowSize from './useWindowSize';

export const useBreakpoints = () => {
  const { windowSize } = useWindowSize();
  const [activeBreakPoint, setActiveBreakpoint] = useState(0);
  const isDesktop = useMemo(() => activeBreakPoint > 0, [activeBreakPoint]);

  useEffect(() => {
    // Handler to call on window resize
    if (windowSize.width) {
      const calculatedNewBreakPoint = calcActiveBreakpoint(windowSize.width);
      if (calculatedNewBreakPoint !== activeBreakPoint) {
        setActiveBreakpoint(calculatedNewBreakPoint);
      }
    }
  }, [activeBreakPoint, windowSize.width]);

  return { activeBreakPoint, isDesktop };
};

const calcActiveBreakpoint = (windowWidth: number) => {
  let breakpointIndex = -1;

  breakpointsRaw.forEach((breakPointPx, index) => {
    if (windowWidth > breakPointPx) {
      breakpointIndex = index;
    }
  });

  return breakpointIndex;
};
