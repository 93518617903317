import styled, { css } from 'styled-components';
import {
  BorderProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TextAlignProps,
  TypographyProps,
  border,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  textAlign,
  typography,
} from 'styled-system';

import { ThemeColor } from '../../theme/tokens';

type BaseFlexProps = SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  GridProps &
  ShadowProps &
  TypographyProps &
  TextAlignProps;

export type FlexProps = Omit<BaseFlexProps, 'color' | 'backgroundColor'> & {
  color?: ThemeColor;
  backgroundColor?: ThemeColor;
  borderColor?: ThemeColor;
};

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${({ theme, color, backgroundColor }) => css`
    ${color && `color: ${theme.colors[color]};`}
    ${backgroundColor && `background-color: ${theme.colors[backgroundColor]};`}
  `};

  /* an addition of "grid" in a "Flex" component may make no-sense, but I unfortunatelly need it */
  /* to use "gridRowGap" and "gridColumnGap" that since recently also work with Flexbox */
  /* styled-system is not aware of that */
  ${compose(space, flexbox, border, grid, textAlign, layout, position, shadow, typography)}
`;
